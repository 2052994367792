import React from 'react';

const About = () => {
  return (
    <div>
      <p>
        <a href="https://imbz.online">bz</a> is bz reading and this service auto-shares his best
        finds.
      </p>
      <p>expect a new episode every three days.</p>
    </div>
  );
};

export default About;
